<template>
  <!-- 支付流水 -->
  <div ref="payFlow" class="payFlow">
    <div ref="ActionBar" class="ActionBar">
      <div class="actionBar-box">
        <div class="col-1">支付时间段：</div>
        <div class="col-2">
          <el-date-picker
            v-model="payTimeValue"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">业务类型：</div>
        <div class="col-2 col-2-a">
          <el-select v-model="businessType" clearable placeholder="请选择">
            <el-option
              v-for="item in businessTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">支付状态：</div>
        <div class="col-2 col-2-a">
          <el-select v-model="payState" clearable placeholder="请选择">
            <el-option
              v-for="item in payStateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">所属公司：</div>
        <div class="col-2 col-2-a">
          <companySelect
            :multiple="true"
            :clearable="true"
            :company-tree="companyList"
            :valuename="valueAddName"
            :value="valueAdd"
            @getValue="getAddGroupId"
          ></companySelect>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">订单号：</div>
        <div class="col-2">
          <el-input v-model="orderNumberValue" clearable></el-input>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">流水号：</div>
        <div class="col-2 col-2-a">
          <el-input v-model="serialNumberValue" clearable></el-input>
        </div>
      </div>
      <div class="actionBar-box">
        <el-button type="primary" size="small" @click="queryFun(1)"
          >查询</el-button
        >
        <el-button
          v-if="listFind('导出')"
          type="primary"
          size="small"
          @click="exportFun"
          >导出</el-button
        >
      </div>
    </div>
    <Table
      :table-height="TableHeight"
      :col-class-type="true"
      :table-data="tableData"
      :title-name="titleName"
    ></Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="pageSize"
        :current-page="currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getCompanyTree, payFlowAPI, payFlowExportAPI } from "@/api/lib/api.js";
import companySelect from "@/components/treeSelect/companySelect.vue";

export default {
  components: { companySelect },
  data() {
    return {
      TableHeight: 0,
      tableData: [],
      titleName: [
        {
          title: "支付流水号",
          props: "transactionId",
          width: 100,
        },
        {
          title: "订单号",
          props: "outTradeNo",
        },
        {
          title: "子订单ID",
          props: "outRefundNo",
        },
        {
          title: "所属公司",
          props: "companyName",
        },
        {
          title: "业务类型",
          props: "businessType",
          SpecialJudgment: (res) => {
            return res === 1
              ? "城际专车"
              : res === 2
              ? "汽车票"
              : res === 4
              ? "包车"
              : res === 5
              ? "租车"
              : res === 6
              ? "定制班线"
              : "";
          },
        },
        {
          title: "流水类型",
          props: "flowType",
          SpecialJudgment: (res) => {
            return res === 0
              ? "支付"
              : res === 1
              ? "退款"
              : res === 2
              ? "退还手续费"
              : "";
          },
          type: "select",
        },
        {
          title: "支付金额",
          props: "fee",
        },
        {
          title: "支付状态",
          props: "state",
          SpecialJudgment: (res) => {
            let s = "";
            switch (res) {
              case "NOTPAY":
                s = "未支付";
                break;
              case "SUCCESS":
                s = "支付成功";
                break;
              case "REFUNDING":
                s = "退款中";
                break;
              case "REFUND":
                s = "退款成功";
                break;
            }
            return s;
          },
        },

        {
          title: "支付手机号",
          props: "passengerPhone",
          width: 100,
        },
        {
          title: "发起时间",
          props: "startTime",
        },
        {
          title: "支付方式",
          props: "payType",
          SpecialJudgment: (res) => {
            return res == 0
              ? "微信"
              : res == 1
              ? "支付宝"
              : res == 2
              ? "银联"
              : "";
          },
        },
      ],
      pageSize: 10,
      currentPage: 1,
      total: 0,
      companyList: [],
      businessTypeList: [
        { value: 1, label: "城际专车" },
        { value: 2, label: "汽车票" },
        { value: 4, label: "包车" },
        { value: 5, label: "租车" },
        { value: 6, label: "定制班线" },
      ],
      payStateList: [
        { value: "SUCCESS", label: "支付成功" },
        { value: "NOTPAY", label: "未支付" },
        { value: "REFUNDING", label: "退款中" },
        { value: "REFUND", label: "退款成功" },
      ],
      valueAddName: null,
      valueAdd: null,
      payTimeValue: "", //支付时间段
      businessType: "", //业务类型
      payState: "", //支付状态
      orderNumberValue: "", //订单号
      serialNumberValue: "", //流水号
      companyIds: "",
      temporaryForm: {},
    };
  },
  created() {
    this.getCompanyList();
    let myDate = new Date();
    this.payTimeValue = [];
    myDate.setMonth(myDate.getMonth() - 1);
    this.payTimeValue[0] =
      myDate.getFullYear() +
      "-" +
      (myDate.getMonth() + 1) +
      "-" +
      myDate.getDate();
    myDate.setMonth(myDate.getMonth() + 1);
    this.payTimeValue[1] =
      myDate.getFullYear() +
      "-" +
      (myDate.getMonth() + 1) +
      "-" +
      myDate.getDate();
  },
  mounted() {
    this.queryFun();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
  methods: {
    // 导出
    exportFun() {
      let t1,
        t2 = "";
      if (this.temporaryForm.startTimeBegin == "") {
        t1 = "";
        t2 = "";
      } else {
        t1 = new Date(this.temporaryForm.startTimeBegin).getTime();
        t2 = new Date(this.temporaryForm.startTimeEnd).getTime();
      }
      payFlowExportAPI({
        startTimeBegin: t1,
        startTimeEnd: t2,
        businessType: this.temporaryForm.businessType,
        companyIds: this.temporaryForm.companyIds,
        state: this.temporaryForm.state,
        outTradeNo: this.temporaryForm.outTradeNo,
        transactionId: this.temporaryForm.transactionId,
        currentPage: this.temporaryForm.currentPage,
        pageSize: this.temporaryForm.pageSize,
      }).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "支付流水表 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");

        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击

        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    // 数据获取方法
    renderData(time1, time2) {
      this.temporaryForm = {
        startTimeBegin: time1,
        startTimeEnd: time2,
        businessType: this.businessType,
        state: this.payState,
        companyIds: this.companyIds,
        outTradeNo: this.orderNumberValue,
        transactionId: this.serialNumberValue,
        pageSize: this.pageSize,
        currentPage: this.currentPage,
      };
      payFlowAPI({
        startTimeBegin: time1,
        startTimeEnd: time2,
        businessType: this.businessType,
        state: this.payState,
        companyIds: this.companyIds,
        outTradeNo: this.orderNumberValue,
        transactionId: this.serialNumberValue,
        pageSize: this.pageSize,
        currentPage: this.currentPage,
      }).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 点击查询按钮
    queryFun(num) {
      if (num && num == 1) {
        this.currentPage = 1;
      }
      let time1 = "";
      let time2 = "";
      if (this.payTimeValue) {
        time1 = this.GMTToStr(this.payTimeValue[0]) + " 00:00:00";
        time2 = this.GMTToStr(this.payTimeValue[1]) + " 23:59:59";
      }
      this.renderData(time1, time2);
    },
    // 计算表格高度
    computeHeight() {
      const boxHeight = this.$refs["payFlow"].clientHeight;
      const topHeight = this.$refs["ActionBar"].clientHeight;
      this.TableHeight = boxHeight - topHeight - 90;
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.currentPage = page;
      this.queryFun();
    },
    // 当前条数改变
    onSizeChange(size) {
      this.pageSize = size;
      this.currentPage = 1;
      this.queryFun();
    },
    // 企业回显
    getAddGroupId(v) {
      this.companyIds = v;
    },
    // 获取企业树
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-range-separator {
  line-height: 26px !important;
}
.feedbackPopup {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  .box {
    width: 700px;
    border-radius: 12px;
    background: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .content {
      padding: 20px;
    }
    .header {
      padding: 18px 24px;
      height: 24px;
      width: calc(100% - 48px);
      border-bottom: 1px solid #d7d7d7;
      .text {
        margin-top: 8px;
        display: inline-block;
        font-size: 14px;
      }
      .close {
        float: right;
        margin-top: 10px;
        cursor: pointer;
        width: 12px;
        height: 12px;
        background: url("../../../assets/images/home/close.png") no-repeat;
        background-size: 100%;
        transform: translateY(-50%);
      }
    }
    .footer {
      padding: 10px 24px 18px 24px;
      height: 32px;
      width: calc(100% - 48px);
      border-bottom: none !important;
      border-top: 1px solid #d7d7d7;
      .el-button {
        float: right;
        margin-left: 14px;
      }
      .el-button:first-child {
        background: #336fee;
        color: #ffffff;
      }
    }
  }
}
.payFlow {
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  padding: 16px 20px;
  background: #ffffff;
  height: calc(100% - 40px);
  .ActionBar {
    background: #f7f8f9;
    padding: 16px 0 0 16px;
    padding-bottom: 0;
    display: flex;
    margin-bottom: 16px;
    flex-flow: wrap;
    .actionBar-box:nth-child(4n) {
      margin-right: 0;
    }
    .actionBar-box {
      width: calc(25% - 18px);
      height: 32px;
      line-height: 32px;
      margin-right: 7px;
      margin-bottom: 16px;
      padding-right: 10px;
      .col-1-a {
        width: 70px !important;
      }
      .col-1 {
        width: 85px;
        display: inline-block;
      }
      .col-2-a {
        width: calc(100% - 70px) !important;
      }
      .col-2 {
        display: inline-block;
        width: calc(100% - 85px);
        .el-select {
          width: 100%;
        }
      }
    }
  }
}
</style>
